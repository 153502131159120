@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 0;
  background: #fff;

  @media (--viewportLarge) {
    padding-bottom: 90px;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 20px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: calc(var(--contentMaxWidth) + 150px);
    padding: 0 36px 0 36px;
    margin: 0 auto 117px auto;
  }

  @media (max-width: 1300px) {
    margin: 20px;
    max-width: 100%;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }

  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 100%;
  max-height: 231px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: -1px -1px 28px 11px rgba(0, 0, 0, 0.12) inset;
  -webkit-box-shadow: -1px -1px 28px 11px rgba(0, 0, 0, 0.12) inset;
  -moz-box-shadow: -1px -1px 28px 11px rgba(0, 0, 0, 0.12) inset;

  & img {
    border-radius: 8px;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }

  @media (max-width: 1124px) {
    width: 100%;
    max-width: 100%;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

/* ................................listing grid design */
.listingGrid {
  display: flex;
  gap: 100px;
  width: 100%;

  @media (max-width: 1300px) {
    gap: 30px;
    justify-content: space-between;
  }

  @media (max-width: 1124px) {
    flex-wrap: wrap;
  }
}

.leftGird {
  position: relative;
  padding-left: 128px;
  width: 60%;

  & .disableOverlayVideo {
    position: relative;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.87);
      z-index: 3;
      content: '';
      border-radius: 0 0 60px 0;
    }
  }

  & video {
    width: 464px;
    height: 474px;
    border-radius: 0px 0px 60px 0px;

    object-fit: cover;

    /* border-radius: 0px 0px 60px 0px; */
    @media (max-width: 1125px) {
      width: 100%;

      /* height: 374px; */
    }

    @media (max-width: 576px) {
      width: 100%;
      height: 374px;
      /* height: 374px; */
    }
  }

  @media (max-width: 1300px) {
    padding-left: 0;
  }

  @media (max-width: 1124px) {
    width: 100%;
  }

  & .rotateHeading {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left;
    transform: translateX(-100%) rotate(-90deg);
    transform-origin: right;
    margin-top: 50px;

    @media (max-width: 1300px) {
      display: none;
    }

    & h1 {
      color: #000;
      font-family: 'Source Serif 4', serif;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      white-space: nowrap;
    }
  }

  & .listingImageBox {
    & img {
      width: 100%;
      object-fit: cover;
      border-radius: 0 0 60px 0;
      pointer-events: none;

      @media (min-width: 768px) {
        height: 474px;
      }
    }

    &.disableOverlay {
      position: relative;
      pointer-events: none;

      &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #666241e5;
        z-index: 3;
        content: '';
        border-radius: 0 0 60px 0;
      }
    }
  }

  & .mobileSpaceBox {
    @media (max-width: 768px) {
      padding: 0 24px;
    }
  }

  & .headingDescription {
    margin-top: 18px;
    padding-bottom: 80px;
    border-bottom: 1px solid #9e9e9e;

    @media (max-width: 1124px) {
      margin-top: 30px;
    }

    & .specialistTitle {
      color: #000;
      /* font-family: 'Poppins'; */
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 66px;
      letter-spacing: 0.18px;
    }

    & .mainHeadingName {
      color: #000;
      font-family: 'Source Serif 4', serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 42px;
      letter-spacing: 0.4px;
      margin: 0;
      padding: 0;
    }

    & .description {
      color: #2f2d2b;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.32px;
      margin-bottom: 0;
      margin-top: 4px;
      padding: 0;
    }
  }

  & .reviewContainer {
    margin-top: 42px;

    & .reviewHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & h4 {
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 66px;
        letter-spacing: 0.22px;
        margin: 0;
      }

      & .seeAllLink {
        & button {
          color: #2f2d2b;
          /* font-family: 'Source Serif 4', serif; */
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 105px;
          letter-spacing: 0.18px;
          text-decoration-line: underline;
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    & .reviewBox {
      display: flex;
      flex-direction: column;
      gap: 44px;
      padding-bottom: 60px;
      border-bottom: 1px solid #9e9e9e;

      & .reviewList {
        display: flex;
        gap: 20px;

        & .reviewLeftSide {
          & img {
            height: 77px;
            width: 77px;
          }
        }

        & .reviewRightSide {
          & .ratingStar {
            display: flex;
            gap: 6px;
          }

          & .reviewName {
            color: #000;
            font-size: 16px;
            font-style: normal;
            line-height: normal;
            margin-top: 10px;

            & .boldName {
              font-weight: 700;
              margin-right: 5px;
            }

            & .normalName {
              font-weight: 300;
            }
          }

          & .reviewMessage {
            margin-top: 11px;
            color: #3c434d;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  & .careerHistory {
    padding-bottom: 0px;
    /* border-bottom: 1px solid #9e9e9e; */
    padding-top: 10px;

    & .careeerHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & h4 {
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 66px;
        letter-spacing: 0.22px;
        margin: 0;
      }

      & .seeAllLink {
        & button {
          color: #2f2d2b;
          /* font-family: 'Source Serif 4', serif; */
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 105px;
          letter-spacing: 0.18px;
          text-decoration-line: underline;
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    & .careerGrid {
      display: flex;
      flex-direction: column;
      gap: 16px;

      & .careerList {
        margin-top: 13px;

        & .companyHeld {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
        }

        & .careerDate {
          color: #000;
          font-size: 14px;
          font-weight: 300;
          line-height: 22px;
          letter-spacing: 0.14px;
        }

        & .careerDescription {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          letter-spacing: 0.32px;
          margin-top: 11px;
        }
      }
    }
  }

  & .interestBox {
    padding-bottom: 50px;
    border-bottom: 1px solid #9e9e9e;
    margin-top: 40px;

    & .interestTitle {
      color: #000;
      font-size: 22px;
      font-style: normal;
      font-weight: 300;
      line-height: 66px;
      letter-spacing: 0.22px;
      margin-bottom: 6px;
    }

    & .interestList {
      display: flex;
      gap: 29px;

      & .interestIcon {
        height: 68px;
        width: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & .imageGallery {
    padding-bottom: 77px;
    border-bottom: 1px solid #9e9e9e;
    margin-top: 75px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 19px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    & img {
      height: 143px;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    & video {
      height: 143px;
      width: 100%;
      border-radius: 8px;
      background: #000;
    }
  }
}

.rightGrid {
  width: 40%;
  padding-top: 50px;

  @media (max-width: 1124px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0 24px;
  }

  & .availabilityWeek {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .availabilityText {
      color: #2f2d2b;
      font-size: 18px;
      font-style: italic;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0.18px;

      & select {
        padding-right: 20px;
        background-position: center right 7px;
      }

      & .boldText {
        color: #2f2d2b;
        font-size: 18px;
        font-style: italic;
        font-weight: 800;
        line-height: 66px;
        letter-spacing: 0.24px;
      }
    }

    & .actionIcon {
      display: flex;
      gap: 16px;

      @media (max-width: 1124px) {
        display: none;
      }
    }
  }

  & .careerHistory {
    padding-bottom: 50px;
    /* border-bottom: 1px solid #9e9e9e; */
    padding-top: 40px;

    & .careeerHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & h4 {
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 66px;
        letter-spacing: 0.22px;
        margin: 0;
      }

      & .seeAllLink {
        & button {
          color: #2f2d2b;
          /* font-family: 'Source Serif 4', serif; */
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 105px;
          letter-spacing: 0.18px;
          text-decoration-line: underline;
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    & .careerGrid {
      display: flex;
      flex-direction: column;
      gap: 16px;

      & .careerList {
        margin-top: 13px;

        & .companyHeld {
          color: #000;
          /* font-family: 'Source Serif 4', serif; */
          font-size: 19px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }

        & .careerDate {
          color: #000;
          font-size: 14px;
          font-style: italic;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.14px;
        }

        & .careerDescription {
          color: #3c434d;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.32px;
          margin-top: 11px;
        }
      }
    }
  }

  & .mapBox {
    padding-bottom: 50px;
    border-bottom: 1px solid #9e9e9e;

    & .mapLocation {
      margin-top: 20px;
    }

    & .locationList {
      display: flex;
      justify-content: space-between;
      margin-top: 18px;
      flex-wrap: wrap;
      gap: 14px;

      @media (max-width: 1300px) {
        flex-wrap: wrap;
        gap: 14px;
      }

      & .locationName {
        & span {
          color: #3c434d;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin-left: 10px;
        }
      }
    }
  }

  & .portfolioBox {
    padding-top: 40px;
    border-top: 1px solid #9e9e9e;
    margin-top: 73px;
    margin-bottom: 20px;

    @media (max-width: 576px) {
      margin-bottom: 60px;
    }

    & .portfolioHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & h4 {
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 66px;
        letter-spacing: 0.22px;
        margin: 0;
      }

      & .seeAllLink {
        & button {
          color: #2f2d2b;
          font-family: 'Source Serif 4', serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 105px;
          letter-spacing: 0.18px;
          text-decoration-line: underline;
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    & .portfolioSlider {
      width: 450px;

      @media (max-width: 576px) {
        width: 100%;
        max-width: calc(100vw - 44px);
      }

      & .sliderBox {
        padding: 0 5px;
        display: flex;
        flex-direction: column;

        & video {
          width: 100%;
          object-fit: contain;
        }

        & .uploadDocument {
        }

        & .sliderImage {
          & :global(.slick-prev) {
            z-index: 10;

            &:before {
              content: unset !important;
            }

            left: 0 !important;

            & svg {
              transform: rotate(178deg);
            }
          }

          & :global(.slick-arrow) {
            top: 47% !important;
          }

          & :global(.slick-next) {
            &:before {
              content: unset !important;
            }

            right: 0 !important;
          }

          & img {
            width: 100%;
            max-height: 230px;
            object-fit: contain;
            cursor: pointer;
          }
        }

        & .sliderData {
          margin-top: 33px;

          & .sliderHeading {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0.56px;
            margin-bottom: 8px;
          }

          & .sliderSubHeading {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0.72px;

            & li {
              position: relative;
              padding-left: 16px;

              &::before {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                background-color: #e57552;
                left: 0;
                top: 13px;
                border-radius: 50%;
              }
            }
          }
        }
      }

      & :global(.slick-dots) {
        & li {
          border-radius: 18px;
          background: #fcf6eb;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

          & button:before {
            opacity: 0 !important;
          }
        }

        & :global(.slick-active) {
          border-radius: 18p !important;
          background: var(--forteh-second-orange, #e57552) !important;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
        }
      }

      & :global(.slick-prev) {
        z-index: 9;
        left: -20px !important;

        &:before {
          content: unset !important;
        }

        @media (max-width: 767px) {
          left: 0 !important;
        }

        & svg {
          transform: rotate(178deg);
        }
      }

      & :global(.slick-next) {
        &:before {
          content: unset !important;
        }

        @media (max-width: 767px) {
          right: 0 !important;
        }
      }

      & :global(.slick-arrow) {
        top: 36% !important;

        & svg {
          & path {
            stroke: #e57552;
          }
        }
      }
    }
  }

  & .qaBox {
    padding-top: 55px;
    border-top: 1px solid #9e9e9e;
    margin-top: 73px;

    &.qaBoxSmallPadding {
      padding-top: 20px;
    }

    &.qaBoxModal {
      margin-top: 0;
      padding-top: 0;
      border: 0;
    }

    & .qaHeading {
      color: #000;
      font-size: 22px;
      font-style: normal;
      font-weight: 300;
      line-height: 66px;
      margin-bottom: 20px;
      letter-spacing: 0.22px;
    }

    & .qaBoxWrapper {
      display: flex;
      gap: 35px;
      flex-direction: column;

      & .qaBoxList {
        border-radius: 8px;
        background: #fcf6eb;
        padding: 33px;

        & .mainHeading {
          color: #000;
          font-family: 'Source Serif 4', serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }

        & .mainDescription {
          color: #3c434d;
          margin-top: 5px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.32px;
        }
      }
    }
  }
}

.modalDetails {
  & > div {
    & > div {
      flex-basis: 776px !important;

      & > div {
        padding: 0 20px;
      }
    }
  }

  & .modalSlider {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;

    & :global(.slick-slider) {
      max-width: 616px !important;
    }

    & :global(.slick-prev) {
      z-index: 10;

      &:before {
        content: unset !important;
      }

      left: 0 !important;

      & svg {
        transform: rotate(178deg);
      }
    }

    & :global(.slick-arrow) {
      top: 47% !important;
    }

    & :global(.slick-next) {
      &:before {
        content: unset !important;
      }

      right: 0 !important;
    }
  }

  & .portfolioSlider {
    width: 100% !important;
  }

  & .portfolioBox {
    margin: 0;
    padding: 0;
    border: 0;

    & video {
      height: auto;
      border-radius: 0;
    }
  }
}

.listingFixBottom {
  position: fixed;
  bottom: 0;
  background-color: #666241;
  height: 95px;
  width: 100%;
  z-index: 99;

  @media (max-width: 1360px) {
    height: auto;
  }

  & .listingBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 36px;

    @media (max-width: 1360px) {
      flex-wrap: wrap;
      padding: 35px;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    @media (max-width: 1124px) {
      padding: 27px;
    }

    & .leftLinks {
      display: flex;
      align-items: center;
      gap: 20px;

      @media (max-width: 1124px) {
        display: none;
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      & a {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.64px;
      }
    }
  }

  & .rightLinks {
    display: flex;
    gap: 38px;
    align-items: center;

    @media (max-width: 1360px) {
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      justify-content: center;
      gap: 10px;
    }

    & .availableData {
      @media (max-width: 1124px) {
        display: none;
      }

      & .negotiableText {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.18px;

        & span {
          color: #fff;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0.33px;
        }
      }

      & .availableText {
        color: #fff;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.18px;
      }
    }

    & .requestButton {
      & button {
        color: #2f2d2b;
        /* font-size: 20px; */
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0.2px;
        background-color: #fff;
        border-radius: 50px;
        width: 284px;
      }
    }
  }
}

.listView {
  cursor: pointer;
  width: 43px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2f2d2b;
  border-radius: 50%;
}

.mobileActionIcon {
  display: none;

  @media (max-width: 1920px) {
    display: block;

    & img {
      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
}

.logoImage {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);

  & .avtarLogo {
    width: 170px;
    height: 170px;
    border-radius: 50%;
        position: relative;
  }

  & .disableOverlay {
    position: relative;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: #666241e5;
      z-index: 300;
      content: '';
      width: 170px;
      height: 170px;
      border-radius: 50%;
    }
  }
  & .videoIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    & svg {
      cursor: pointer;
    }
  }
  & .videoBox {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 4;
    object-fit: cover;
    position: relative;
    & video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
    & .disableOverlay {
    position: relative;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: #666241e5;
      z-index: 300;
      content: '';
      width: 170px;
      height: 170px;
      border-radius: 50%;
    }
  }
}

.SelectedPills {
  background-color: #e57552 !important;
  color: #fff !important;
}
.NoVideoIcon{
  display: none;
}

.companySocialLinksContent {
  padding: 42px 23px;
  border-radius: 0px 0px 40px 0px;
  background: #666241;
  position: relative;

  @media (max-width: 767px) {
    border-radius: 0;
  }

  & ul {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 20px;

    & li {
      margin: 0;
      padding: 0;
    }
  }

  & .socialLinks {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  & .companyFollowBtn {
    color: #fff;
    text-align: center;
    font-family: 'Source Serif 4';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 750% */
    letter-spacing: 0.14px;
    padding: 7px 20px;
    border-radius: 100px;
    border: 1px solid #fff;
    background: #666241;
  }
}

.listingProfileImageBox {
  position: absolute;
  width: 157px;
  height: 157px;
  bottom: -80px;
  left: 0px;
  overflow: hidden;

  @media (max-width: 767px) {
    left: 18px;
  }

  & > div {
    height: 100%;
  }
}

.companyName {
  padding-left: 183px;
  padding-top: 31px;

  @media (max-width: 767px) {
    margin-top: 100px;
    padding: 30px 22px;
  }

  & h6 {
    color: #000;
    font-family: Source Serif 4;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 0 0 8px;
    padding: 0;
    text-transform: inherit;
  }

  & h4 {
    color: #000;
    font-family: Source Serif 4;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.28px;
    margin: 0;
    padding: 0;
  }
}

.aboutUs {
  margin-top: 34px;

  & h5 {
    color: #000;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.22px;

    @media (max-width: 767px) {
      padding: 0 22px;
    }
  }

  & p {
    color: var(--forteh-prime-black, #2f2d2b);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: 0.32px;
  }
}

.leftGird {
  & .sectionMap {
    & h2 {
      display: none;
    }

    & .map {
      max-height: 347px;
    }
  }

  & .locationName {
    margin-top: 27px;

    & span {
      color: #3c434d;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-left: 10px;
    }
  }

  & .mapBox {
    margin-top: 35px;

    @media (max-width: 767px) {
      margin-top: 10px;
      padding: 0 10px;
    }

    & .locationList {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @media (max-width: 767px) {
        flex-direction: column;
        padding: 0 22px;
        gap: 10px;
      }

      & .locationName {
        display: flex;

        &:last-child {
          & span {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.companyLayout {
  max-width: 1340px;
}

.companyRightGrid {
  max-width: 366px;

  & h5 {
    color: #000;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;

    letter-spacing: 0.22px;
    margin: 0 0 30px;
  }

  & .positions {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    margin-bottom: 38px;

    & .cardTop {
      display: flex;
      padding: 5px 15px 10px;
      gap: 20px;

      & h4 {
        color: var(--forteh-prime-black, #2f2d2b);
        font-family: 'Source Serif 4';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 130% */
        letter-spacing: 0.4px;
        margin: 0;
        padding: 0;
      }

      & .hourlyRate {
        margin: 0;
        padding: 0;
        color: #2f2d2b;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
        /* 185.714% */
        letter-spacing: 0.28px;
      }

      & ul {
        margin: 0;
        padding: 0 0 0 20px;
        list-style: disc;

        & li {
          margin: 0;
          padding: 0;
          color: #2f2d2b;
          font-family: 'Poppins';
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 26px;
          /* 185.714% */
          letter-spacing: 0.28px;
        }
      }
    }

    & .positionsImage {
      width: 75.044px;
      height: 75.044px;
      border-radius: 4px;
      overflow: hidden;
    }

    & .cardBottom {
      padding: 15px 25px;
      background: #fcf6eb;

      & .seeDetailsBtn {
        color: #2f2d2b;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.28px;
        text-decoration-line: underline;
        cursor: pointer;
      }

      & p {
        color: var(--forteh-prime-black, #2f2d2b);
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
        letter-spacing: 0.28px;
        margin: 0;
      }
    }
  }
}

.companyListingGrid {
  gap: 87px;
}

.jobmodalDetails {
  @media (max-width: 767px) {
    & .jobModalContent {
      margin-top: 40px;
    }
  }

  & .modalTitle {
    color: #2f2d2b;
    text-align: center;
    font-family: 'Source Serif 4';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 100% */
    letter-spacing: 0.52px;
    margin: 0;
    padding: 0;
  }

  & .companyName {
    color: #2f2d2b;
    font-family: 'Source Serif 4';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.36px;
    text-decoration-line: underline;
    text-align: center;
    margin: 0 0 28px;
    padding: 0;
  }

  & .jobRequirements {
    padding: 0;
    margin: 0;

    & li {
      display: flex;
      gap: 16px;
    }
  }

  & .modalSectionTitle {
    color: #4a4a4a;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.348px;
  }

  & .jobDescription {
    color: #2f2d2b;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: 0.32px;
  }

  & .companiesList {
    list-style: disc;
    padding-left: 20px;
    margin: 0;
  }

  & p {
    color: #4a4a4a;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.348px;
    margin: 0;
    padding: 0;
  }

  & .buttonInterested {
    color: #fff;
    text-align: center;
    font-family: Source Serif 4;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.18px;
    background: #666241;
    border-radius: 25px;
    margin-top: 30px;
  }
}

.notSignedInContent {
  & h4 {
    color: #000;
    font-family: 'Source Serif 4';
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 66px;
    /* 165% */
    letter-spacing: 0.4px;
  }

  & a {
    max-width: 284px;
    margin: 0 auto;
  }

  & button {
    border-radius: 100px;
    background: #e57552;
    max-width: 284px;
    margin: 0 auto;
    min-height: 72px;
  }
}

.showmore {
  color: #2f2d2b;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.32px;
  cursor: pointer;
}

.deetsFrom {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  /* font-family: Source Serif 4; */
  line-height: 22px;

  & .ProDeetsLabel {
    font-weight: 500;
  }
}

.deetsHeading {
  color: #3c434d;
  /* font-family: 'Source Serif 4', serif; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin: 16px 0 16px;
  padding: 0;
  text-transform: capitalize;
}

.deetsDescription {
  color: #3c434d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.32px;
}

.iconWrapper {
  margin-bottom: 12px;
}

.fullScreenView {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.82);

  & .responsiveImage {
    height: 100%;

    & div {
      height: 100%;

      & img {
        height: auto;
        max-height: 90%;
      }
    }
  }

  & img {
    object-fit: contain;
    border-radius: 0;
  }
}

.closeFullScreen {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  min-height: 40px;
  background: #00000082;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #000000bd;

    & svg {
      & rect {
        fill: #fff;
        stroke: #fff;
      }
    }
  }

  & svg {
    & rect {
      fill: #ededed;
      stroke: #ededed;
    }
  }
}

.firstConnectionPopup {
  & > div {
    align-items: center !important;

    & > div {
      border-bottom: 0 !important;
      border-radius: 10px !important;

      & > button {
        & span {
          display: none !important;
        }
      }

      & .popupContent {
        text-align: center;

        & svg {
          margin-right: 15px;
        }
      }
    }
  }
}

.qualificationBox {
  margin-top: 28px;

  & .qualificationHeading {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 66px;
    letter-spacing: 0.22px;
  }

  & .qualificationList {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 20px;
    margin-bottom: 16px;

    & .qualificationName {
      border-radius: 100px;
      border: 1px solid #e57552;
      background: #fff;
      padding: 8px 14px;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.14px;
      position: relative;

      & svg {
        margin-right: 10px;
      }
    }

    & .parentElement {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      margin: 0px 0 0px;
      /* border-bottom: 1px solid #ddd;
      padding-bottom: 16px; */
    }

    & .Skillspopup {
      display: none;
      position: absolute;
      bottom: 140%;
      left: 0;
      right: 0;
      align-items: center;
      justify-content: center;

      & .SkillspopupBorder {
        border: 1px solid #666241;
        background: #fcf6eb;
        white-space: nowrap;
        padding: 19px 30px;
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        letter-spacing: 0.14px;
        border-radius: 30px;
      }
    }

    & .experiencePopup {
      position: relative;
      border-radius: 100px;
      border: 1px solid #e57552;
      background: #fff;
      padding: 8px 14px;
      color: #000;
      font-size: 10px;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
      letter-spacing: 0.14px;

      &:hover {
        &:after {
          content: '';
          width: 31px;
          height: 26px;
          background-position: center;
          bottom: 90%;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.984522 11.0049L0.959788 4.8799L6.41536 0.0578293L11.8847 4.83578L11.9094 10.9607L6.44009 6.18278L0.984522 11.0049Z' fill='black'/%3E%3C/svg%3E%0A");
        }

        & .Skillspopup {
          display: flex;
        }
      }
    }
  }
}

.moreCompanyInfo {
  font-size: 16px;
  margin-top: 20px;

  & > div {
    display: flex;
    gap: 10px;
    line-height: 32px;

    & b {
      min-width: 300px;

      @media (max-width: 567px) {
        min-width: auto;
      }
    }
  }
}
.morePadding{
  padding-bottom: 100px !important;
}


.rightLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.closeListing {
  cursor: pointer;
  margin: 0;
  margin-right: 20px;
  font-size: 14px;
  padding: 14px 24px 11px 12px;

  @media (--viewportMedium) {
    padding: 20px 24px 22px 12px;
  }
}

.sliderHeading {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.56px;
  margin-bottom: 8px;
}

.moreSub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;

  & > span {
    position: relative;
    padding-left: 16px;

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: #e57552;
      left: 0;
      top: 9px;
      border-radius: 50%;
    }
  }

  & > p {
    font-size: 14px;
    color: #e57552;
    cursor: pointer;
    text-decoration: underline;
    margin: 0;
  }
}